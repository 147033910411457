import { FC } from 'react';

import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Stack, Icon, Link } from '@chakra-ui/react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'react-i18next';

import { NavItem } from '../Header.types';

const DesktopSubNav: FC<NavItem> = ({ label, href, subLabel }) => {
    const { t } = useTranslation();

    return (
        <Link
            href={href}
            to={href as string}
            as={AnchorLink}
            role={'group'}
            display={'block'}
            p="4"
            rounded="md"
            _hover={{ bg: 'gray.100' }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text transition={'all .3s ease'} _groupHover={{ color: 'orange.400' }} fontWeight={500}>
                        {t(label)}
                    </Text>
                    {subLabel && <Text fontSize={'sm'}>{t(subLabel)}</Text>}
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{
                        opacity: '100%',
                        transform: 'translateX(0)',
                    }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'orange.400'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Link>
    );
};

export default DesktopSubNav;
