import { FC, ReactNode } from 'react';

import {
    Button,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Modal,
} from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import { modalAtom } from '@/state/modal/modal';

import Trans from '../Trans/Trans';

const ModalWrapper: FC<{ children: ReactNode }> = ({ children }) => {
    const { t } = useTranslation();
    const [modal, setModal] = useAtom(modalAtom);

    const handleClose = () => {
        setModal(false);
    };

    return (
        <>
            <Modal isOpen={modal} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('discount.title')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Trans i18nKey="discount.text" />
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleClose}>
                            {t('common.close')}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {children}
        </>
    );
};

export default ModalWrapper;
