import { FC } from 'react';

import { Box, Link, Stack, Text } from '@chakra-ui/react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useTranslation } from 'react-i18next';

import { FooterNavMapProps } from './Footer.types';
import FooterListHeader from './FooterListHeader';
import FooterListSubHeader from './FooterListSubHeader';

const FooterNavMap: FC<FooterNavMapProps> = ({ navItems, title }) => {
    const { t } = useTranslation();
    return (
        <Stack>
            <FooterListHeader>{t(title)}</FooterListHeader>
            {navItems.map(navItem => {
                if (navItem?.children && navItem.children.length > 0) {
                    return (
                        <Box key={navItem.label}>
                            <FooterListSubHeader>{t(navItem.label)}</FooterListSubHeader>
                            {navItem.children.map(navChild => (
                                <Link
                                    as={AnchorLink}
                                    to={navChild.href ?? '#'}
                                    key={navItem.label}
                                    width="fit-content"
                                    alignSelf="center">
                                    <Text fontSize="sm" fontWeight="light" width="fit-content">
                                        {t(navChild.label)}
                                    </Text>
                                </Link>
                            ))}
                        </Box>
                    );
                } else {
                    return (
                        <Link as={AnchorLink} to={navItem.href ?? '#'} key={navItem.label} width="fit-content">
                            <FooterListSubHeader>{t(navItem.label)}</FooterListSubHeader>
                        </Link>
                    );
                }
            })}
        </Stack>
    );
};

export default FooterNavMap;
